.terms-and-conditions-text {
    height: 111px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #667085;
    overflow: auto;
    padding: 16px;
}

.checkbox-container {
    padding: 16px;
    display: flex;
}

.checkbox-container > .checkbox-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #667085;
    margin-left: 16px;
}

.round-cb {
    position: relative;
}

.round-cb label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
}

.round-cb label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 6px;
}

.round-cb input[type="checkbox"] {
    visibility: hidden;
}

.round-cb input[type="checkbox"]:checked + label {
    background-color: #0071EF;
    border-color: #0071EF;
}

.round-cb input[type="checkbox"]:checked + label:after {
    opacity: 1;
}
