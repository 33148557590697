.error-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 64px);
    padding: 16px;
}

.error-page button {
    margin-top: 32px;
    align-self: center;
}
