small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #484B5C;
    margin: 6px 0;
}

.payment-section input, select {
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
}

.payment-section form {
    margin: 0 16px;
}
.payment-section .secure-info {
    padding: 16px;
    border-radius: 0 0 10px 10px;
}

.payment-section button {
    width: 100%;
    margin: 16px 0;
}
.payment-section .error-info {
    border-radius: 10px 10px 0 0;
    background: #FFDFDF;
    padding: 24px 16px;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.1px;
    color: #E52828;
}
.payment-section .error-info img {
    margin-right: 24px;
}

.payment-section .supported-cards {
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F9FAFB;
    border-bottom: 1px solid #E0E6EB;
}
.payment-section .supported-cards:first-of-type {
    border-radius: 10px 10px 0 0;
}

.payment-section .supported-cards img {
    margin-left: 24px
}

.payment-section .rccs {
    margin: 24px auto;
}
.payment-section .zip-state-row {
    display: flex;
}
.payment-section .zip-state-row div:first-of-type{
    margin-right: 8px;
}
.payment-section .zip-state-row div:nth-of-type(2){
    margin-left: 8px;
}
.payment-section .zip-state-row .form-group{
    flex: 1;
}
form {
    margin-top: 32px;
}


.secure-info {
    display: flex;
    align-items: center;
    padding: 16px 0;
    background: #F9FAFB;
}

.secure-info img {
   margin-right: 12px;
}
.secure-info div {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #667085;
}

.switch-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.switch-wrapper>div{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #667085;
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #667085;
}
input:checked + .slider:before {
    background-color: #0071EF;
}


input:checked + .slider {
    background-color: #E0E6EB;
}


input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
