.usd-input-wrapper span {
    position: absolute;
    margin-left: 10px;
}

.divider {
    width: 100%;
    height: 1px;
    background: #E0E6EB;
}
