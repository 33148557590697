*{font-family:'Roboto'}


h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #484B5C;
}

h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #667085;
}

input[type=text], input[type=number], input[type=tel], select {
    padding: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    border: 1px solid #E0E6EB;
    border-radius: 5px;
    color: #667085;
}

button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    border: none;
    max-width: 100%;
}

.button-primary {
    padding: 12px 24px;
    gap: 6px;
    width: 575px;
    height: 48px;
    background: #0071EF;
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.1px;
}
.button-primary:disabled {
    background: #F9FAFB;
    color: #979FAF;
}

.button-secondary-small {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 6px 12px;
    gap: 6px;
    width: 114px;
    height: 32px;
    background: #E9F3FF;
    border-radius: 8px;
    color: #484B5C;
}

.info-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #667085;
}

.payment-container {
    max-width: 607px;
    padding: 105px;
}

@media only screen and (max-width: 768px) {
    .payment-container {
        padding: 16px;
    }
}

.section-container {
    border: 1px solid #E0E6EB;
    border-radius: 10px;
}

.section-container > .title-row {
    background: #F9FAFB;
    padding: 16px;
    border-radius: 10px 10px 0 0;
}

.section-container > .title-row > h2 {
    margin: 0
}

.section-container .info-section {
    padding: 10px 16px 5px;
    border-bottom: 1px solid #E0E6EB;
}

.section-container .info-section > .info-row {
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.section-container .info-section > .info-row.info-number-view {
    justify-content: space-between;
}

.section-container .info-section > .info-row.info-number-view input {
    width: 145px;
    text-align: right;
    padding-right: 0;
}

.section-container .info-section > .info-row div:first-child {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    min-width: 140px;
    letter-spacing: 0.1px;
    color: #667085;
}

.section-container .info-section > .info-row div:nth-child(2) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.1px;
    color: #484B5C;
}

.checkbox-container {
    padding: 16px;
    display: flex;
}

.checkbox-container > .checkbox-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #667085;
    margin-left: 16px;
}

.round-cb {
    position: relative;
}

.round-cb label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
}

.round-cb label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 6px;
}

.round-cb input[type="checkbox"] {
    visibility: hidden;
}

.round-cb input[type="checkbox"]:checked + label {
    background-color: #0071EF;
    border-color: #0071EF;
}

.round-cb input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

