header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px 105px;
    box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
    header {
        padding: 16px;
    }
    header div:first-child {
        display: none;
    }
}

header img {
    width: 150px;
}

header a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    border: none;
    max-width: 100%;
    padding: 0 !important;
    text-decoration: none;
}

.contact-section {
    display: flex;
    align-items: center;
}

.contact-section a {
    margin-left: 16px;
}
